export enum UserRole {
  ADMIN = 'actimirror-admin',
  ORG_ADMIN = 'actimirror-org-admin',
  USER = 'actimirror-user',
}

export enum DisplayUserRole {
  ADMIN = 'aM-admin',
  ORG_ADMIN = 'B2B-admin',
  USER = 'B2C-user',
}

export enum DeviceOwnerType {
  ORG = 'aM-Retail (B2B)',
  USER = 'LUNNA (B2C)',
}

export enum ConfigSyncStatus {
  OUT_OF_SYNC = 'out_of_sync',
  SENT = 'sent',
  ACKNOWLEDGED = 'acknowledged',
  SYNCED = 'synced',
  ERROR = 'error',
}
