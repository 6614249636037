import httpStatus from 'http-status';

import { IRequestOptions } from '../interfaces';
import { getAuthHeaders } from './utils';

export const getConfigs = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/config', {
    headers,
  });

  const { data, error } = await response.json();

  // hidden empty configs error
  if (response?.status === httpStatus.NOT_FOUND) {
    return [];
  }

  if (error) {
    throw error;
  }

  return data;
};

export const getConfigById = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/config/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getConfigByDeviceId = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/config/devices/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getConfigByDeviceGroupId = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/config/groups/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const postConfig = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/config', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.CREATED) {
    const { error } = await response.json();
    throw error;
  }

  const { data } = await response.json();
  return data;
};

export const putConfig = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/config/${params.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.NO_CONTENT) {
    const { error } = await response.json();
    throw error;
  }
};

export const changeDeviceConfig = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(
    `/v2/config/${params.deviceConfigId}/devices/${params.deviceId}`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        autoStartAppId: params.autoStartAppId,
        overridePayload: params.overridePayload,
        revision: params.revision,
      }),
    },
  );

  if (response?.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};

export const changeDeviceGroupConfig = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(
    `/v2/config/${params.deviceConfigId}/groups/${params.groupId}`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        autoStartAppId: params.autoStartAppId,
        overridePayload: params.overridePayload,
        revision: params.revision,
      }),
    },
  );

  if (response?.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};
