import { IOauthToken } from '../interfaces';

const getHeaders = () => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  return headers;
};

const getAuthHeaders = (tokens: IOauthToken) => {
  const { accessToken, tokenType } = tokens;
  const authHeaders = getHeaders();
  authHeaders.append('Authorization', `${tokenType} ${accessToken}`);
  return authHeaders;
};

export { getAuthHeaders, getHeaders };
