const Config = {
  env: process.env.REACT_APP_ENV || 'uat',
  aws: {
    iot: {
      endpoint:
        process.env.REACT_APP_AWS_IOT_MQTT_ENDPOINT ||
        'ad73tv89lsjz8-ats.iot.ap-southeast-1.amazonaws.com',
    },
    region: process.env.REACT_APP_AWS_REGION || 'ap-southeast-1',
    s3: {
      avatarBucketId:
        process.env.REACT_APP_CONTENT_S3_BUCKET || 'am-cloud2-avatar-uat',
    },
  },
};

export default Config;
