import React, { Suspense } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';

import './App.css';

import './scss/style.scss';
import { useAuth } from './contexts/auth';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const Layout = React.lazy(() => import('./views/containers/Layout'));

// Pages
const Login = React.lazy(() => import('./views/pages/Login'));
const ForgotPassword = React.lazy(
  () => import('./views/pages/unauthenticated/ForgotPassword'),
);
const ConfirmPassword = React.lazy(
  () => import('./views/pages/unauthenticated/ConfirmPassword'),
);

const AuthApp = () => (
  <HashRouter>
    <Suspense fallback={loading}>
      <Switch>
        <Route render={(props) => <Layout {...props} />} />
      </Switch>
    </Suspense>
  </HashRouter>
);

const UnauthApp = () => (
  <HashRouter>
    <Suspense fallback={loading}>
      <Switch>
        <Route
          path="/forgot"
          render={(props) => <ForgotPassword {...props} />}
        />
        <Route
          path="/confirm"
          render={(props) => <ConfirmPassword {...props} />}
        />
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Route path="*" render={() => <Redirect to="/login" />} />
      </Switch>
    </Suspense>
  </HashRouter>
);

const App = () => {
  const auth = useAuth();
  return auth.isAuthenticated() ? <AuthApp /> : <UnauthApp />;
};

export default App;
