import {
  authorize,
  token,
  userInfo,
  changePassword,
  forgotPassword,
  confirmForgotPassword,
  confirmPassword,
} from './oauth';
import {
  getConfigs,
  getConfigByDeviceId,
  getConfigByDeviceGroupId,
  getConfigById,
  postConfig,
  putConfig,
  changeDeviceConfig,
  changeDeviceGroupConfig,
} from './config';
import {
  getContents,
  getContentById,
  postContent,
  putContent,
  dropContent,
  putUploadUrl,
  postUploadComplete,
  getDownloadUrl,
} from './content';
import {
  getDevices,
  getDeviceById,
  putDevice,
  dropDevice,
  getDeviceModels,
  getDeviceModelById,
  postDeviceModel,
  putDeviceModel,
  putOwnership,
  getDeviceGroups,
  getDeviceGroupById,
  postDeviceGroup,
  putDeviceGroup,
  changeDeviceGroup,
  getDeviceAccessCode,
} from './device';
import {
  getOrganizations,
  getOrganizationById,
  postOrganization,
  putOrganization,
} from './organization';
import { getUsers, getUserById, postUser, putUser, dropUser } from './user';

export const handleFetch = async (auth, callable, params) => {
  try {
    const result = await callable({
      authData: auth.data,
      params,
    });
    return [result];
  } catch (e) {
    if (e?.name === 'TokenExpiredError') {
      try {
        await auth.refreshToken();
        return [
          await callable({
            authData: auth.data,
            params,
          }),
        ];
      } catch (refreshError) {
        auth.logout();
      }
    }

    return [null, e?.error || e];
  }
};

const api = {
  authorize,
  token,
  changePassword,
  userInfo,
  forgotPassword,
  confirmForgotPassword,
  confirmPassword,
  getDevices,
  getDeviceById,
  putDevice,
  dropDevice,
  getDeviceModels,
  getDeviceModelById,
  postDeviceModel,
  putDeviceModel,
  getDeviceGroups,
  getDeviceGroupById,
  postDeviceGroup,
  putDeviceGroup,
  changeDeviceGroup,
  getDeviceAccessCode,
  putOwnership,
  getUsers,
  getUserById,
  postUser,
  putUser,
  dropUser,
  getContents,
  getContentById,
  postContent,
  putContent,
  dropContent,
  putUploadUrl,
  getDownloadUrl,
  postUploadComplete,
  getConfigs,
  getConfigByDeviceId,
  getConfigByDeviceGroupId,
  getConfigById,
  postConfig,
  putConfig,
  changeDeviceConfig,
  changeDeviceGroupConfig,
  getOrganizations,
  getOrganizationById,
  postOrganization,
  putOrganization,
};

export default api;
