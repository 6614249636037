import camelize from 'camelcase-keys';
import { formatISO } from 'date-fns';

import Config from './config';
import { DisplayUserRole, UserRole } from './enum';
import { IDeviceConfig, ISimpleDeviceConfig } from './interfaces';
export const capitalize = (input: string) =>
  input.charAt(0).toUpperCase() + input.slice(1);

export const getConfigName = (
  deviceConfig: IDeviceConfig | ISimpleDeviceConfig,
) =>
  deviceConfig?.name
    ? `${deviceConfig?.name}_${deviceConfig?.environment}`
    : 'N/A';

// TODO: This is a MOCK! correct this to be the real path
export const userAvatarPath = (user) =>
  `https://${Config.aws.s3.avatarBucketId}.s3.${Config.aws.region}.amazonaws.com/users/${user.id}`;

// TODO: This is a MOCK! correct this to be the real path
export const orgImagePath = (orgId) =>
  `https://${Config.aws.s3.avatarBucketId}.s3.${Config.aws.region}.amazonaws.com/orgs/${orgId}`;

export const constructBatchTaskID = (user) =>
  `BATCH-${user.id}:${formatISO(new Date())}`;

export const constructTaskID = (user) =>
  `TASK-${user.id}:${formatISO(new Date())}`;

export const convertUserRole = (userRole: string) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return DisplayUserRole.ADMIN;
    case UserRole.ORG_ADMIN:
      return DisplayUserRole.ORG_ADMIN;
    default:
      return DisplayUserRole.USER;
  }
};

export { camelize };
