import React from 'react';
import { IntlProvider } from 'react-intl';
import App from './App';

function loadLocaleData(locale: string) {
  switch (locale) {
    case 'zh-HK':
    case 'zh':
    case 'zh-TW':
      return import('./lang/zh-hant.json');
    case 'zh-CN':
      return import('./lang/zh-hans.json');
    case 'ja':
      return import('./lang/jp.json');
    default:
      return import('./lang/en.json');
  }
}

export default async function bootstrapApplicationWithIntl(locale: string) {
  const messages = await loadLocaleData(locale);
  return (
    <IntlProvider
      locale={locale}
      messages={messages.default}
      defaultLocale="en-US"
    >
      <App />
    </IntlProvider>
  );
}
