import httpStatus from 'http-status';

import { IDeviceGroup, IRequestOptions } from '../interfaces';
import { getConfigByDeviceGroupId } from './config';
import { getAuthHeaders } from './utils';

export const getDevices = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/devices', {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getDeviceById = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const putDevice = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/${params.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response?.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};

export const dropDevice = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData?.tokens);

  const response = await fetch(`/v2/devices/${params.id}`, {
    method: 'DELETE',
    headers,
  });

  if (response?.status !== httpStatus.OK) {
    const error = await response.json();
    throw error;
  }
};

export const getDeviceModels = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/models`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getDeviceModelById = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/models/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const postDeviceModel = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/devices/models', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.CREATED) {
    const { error } = await response.json();
    throw error;
  }

  const { data } = await response.json();
  return data;
};

export const putDeviceModel = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/models/${params.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.NO_CONTENT) {
    const { error } = await response.json();
    throw error;
  }
};

export const putOwnership = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const url = params?.orgId
    ? `/v2/devices/${params.id}/bind`
    : `/v2/devices/${params.id}/activate`;

  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response?.status !== httpStatus.CREATED) {
    const error = await response.json();
    throw error;
  }
};

export const getDeviceGroups = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/devices/groups', {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getDeviceGroupById = async ({
  authData,
  params,
}: IRequestOptions): Promise<IDeviceGroup> => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/groups/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  try {
    const config = await getConfigByDeviceGroupId({ authData, params });
    return { ...data, config };
  } catch (e) {
    return data;
  }
};

export const postDeviceGroup = async ({
  authData,
  params,
}: IRequestOptions): Promise<void> => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/devices/groups', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  const { data, error } = await response.json();

  if (response?.status !== httpStatus.CREATED) {
    throw error;
  }

  return data;
};

export const putDeviceGroup = async ({
  authData,
  params,
}: IRequestOptions): Promise<void> => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/groups/${params.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response?.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};

export const changeDeviceGroup = async ({
  authData,
  params,
}: IRequestOptions): Promise<void> => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/groups/${params.id}`, {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response?.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};

export const getDeviceAccessCode = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/devices/${params.id}/totp`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};
