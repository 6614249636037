import httpStatus from 'http-status';
import { IRequestOptions } from '../interfaces';
import { getAuthHeaders } from './utils';

export const getUsers = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/users?limit=1000', {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getUserById = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/users/${params.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const postUser = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/users', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.CREATED) {
    const error = await response.json();
    throw error;
  }

  const { data } = await response.json();
  return data;
};

export const putUser = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/users/${params.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};

export const dropUser = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/users/${params.id}`, {
    method: 'DELETE',
    headers,
  });

  if (response.status !== httpStatus.OK) {
    const error = await response.json();
    throw error;
  }
};
