import React from 'react';
import ReactDOM from 'react-dom';

import { icons } from './assets/icons';
import bootstrapApplicationWithIntl from './bootstrap';
import AppProviders from './contexts';
import './index.css';
import reportWebVitals from './reportWebVitals';

React['icons'] = icons; // eslint-disable-line dot-notation

bootstrapApplicationWithIntl(navigator.language || 'en').then((app) => {
  ReactDOM.render(
    <AppProviders>{app}</AppProviders>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
