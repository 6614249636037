import React from 'react';

import { AuthProvider } from './auth';
import { LayoutProvider } from './layout';
import { ToastProvider } from './toast';

const AppProviders = (props) => (
  <ToastProvider>
    <AuthProvider {...props}>
      <LayoutProvider {...props}></LayoutProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProviders;
