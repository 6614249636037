import React, { useContext, useLayoutEffect, useState } from 'react';

const LayoutContext = React.createContext({
  showingSidebar: true,
  setShowingSidebar: (isShowing: boolean) => {},
  showLoading: false,
  setShowLoading: (isShowing: boolean) => {},
});

const LayoutProvider = (props) => {
  const [showingSidebar, setShowingSidebar] = useState(window.innerWidth > 991);
  const [showLoading, setShowLoading] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => setShowingSidebar(window.innerWidth > 991);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <LayoutContext.Provider
      value={{
        showingSidebar,
        setShowingSidebar,
        showLoading,
        setShowLoading,
      }}
      {...props}
    />
  );
};

const useLayout = () => useContext(LayoutContext);

export { LayoutProvider, useLayout };
