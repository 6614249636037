import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import api from '../apis';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { IAuth } from '../interfaces';

const AuthContext = React.createContext({
  data: null,
  refreshToken: () => {},
  login: async (username: string, password: string): Promise<void> => {},
  logout: (): void => {},
  changePassword: (oldPassword: string, newPassword: string): void => {},
  isAuthenticated: (): boolean => false,
  forgotPassword: (username: string): void => {},
  confirmForgotPassword: (
    username: string,
    code: string,
    newPassword: string,
  ): void => {},
  confirmPassword: (
    username: string,
    code: string,
    newPassword: string,
  ): void => {},
});

const initialState: IAuth = {};

const KEY_AUTH = 'auth';

const AuthProvider = (props) => {
  const [authData, setAuthData] = useLocalStorage(KEY_AUTH, initialState);

  const login = async (username: string, password: string) => {
    const authorizedData = await api.authorize(username, password);
    const userInfoData = await api.userInfo({ tokens: authorizedData });

    setAuthData({
      tokens: authorizedData,
      user: userInfoData,
    });
  };

  const forgotPassword = async (username: string) =>
    api.forgotPassword(username);

  const confirmForgotPassword = async (
    username: string,
    code: string,
    newPassword: string,
  ) => api.confirmForgotPassword(username, code, newPassword);

  const confirmPassword = async (
    username: string,
    code: string,
    newPassword: string,
  ) => {
    const authorizedData = await api.confirmPassword(
      username,
      code,
      newPassword,
    );
    const userInfoData = await api.userInfo({ tokens: authorizedData });

    setAuthData({
      tokens: authorizedData,
      user: userInfoData,
    });
  };

  const logout = () => setAuthData(initialState);

  const isAuthenticated = (): boolean => {
    if (!authData?.tokens) {
      return false;
    }

    if (!authData.user) {
      return false;
    }

    return true;
  };

  const changePassword = async (oldPassword, newPassword) =>
    api.changePassword(authData, oldPassword, newPassword);
  const refreshToken = async () => {
    const authorizedData = await api.token(authData?.tokens?.refreshToken);
    const userInfoData = await api.userInfo({ tokens: authorizedData });

    setAuthData({
      tokens: {
        ...authorizedData,
        refreshToken: authData?.tokens?.refreshToken,
      },
      user: userInfoData,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        data: authData,
        login,
        logout,
        changePassword,
        forgotPassword,
        confirmForgotPassword,
        confirmPassword,
        isAuthenticated,
        refreshToken,
      }}
      {...props}
    />
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
