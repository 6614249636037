import httpStatus from 'http-status';
import { IRequestOptions } from '../interfaces';
import { getAuthHeaders } from './utils';

export const getContents = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/contents', {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getContentById = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/contents/${params?.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const postContent = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch('/v2/contents', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const putContent = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/contents/${params?.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.NO_CONTENT) {
    const { error } = await response.json();
    throw error;
  }
};

export const dropContent = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/contents/${params?.id}`, {
    method: 'DELETE',
    headers,
  });

  if (response.status !== httpStatus.OK) {
    const { error } = await response.json();
    throw error;
  }
};

export const putUploadUrl = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/contents/${params?.id}/upload`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const postUploadComplete = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/contents/${params?.id}/upload/complete`, {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.OK) {
    const { error } = await response.json();
    throw error;
  }
};

export const getDownloadUrl = async ({ authData, params }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);

  const response = await fetch(`/v2/contents/${params?.id}/download`, {
    method: 'GET',
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};
