import httpStatus from 'http-status';

import { IRequestOptions } from '../interfaces';
import { getAuthHeaders } from './utils';

export const getOrganizations = async ({ authData }: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);
  const response = await fetch('/v2/organizations', {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const getOrganizationById = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);
  const response = await fetch(`/v2/organizations/${params?.id}`, {
    headers,
  });

  const { data, error } = await response.json();

  if (error) {
    throw error;
  }

  return data;
};

export const postOrganization = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);
  const response = await fetch('/v2/organizations', {
    method: 'POST',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.CREATED) {
    const error = await response.json();
    throw error;
  }

  const { data } = await response.json();
  return data;
};

export const putOrganization = async ({
  authData,
  params,
}: IRequestOptions) => {
  const headers = getAuthHeaders(authData.tokens);
  const response = await fetch(`/v2/organizations/${params?.id}`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
  });

  if (response.status !== httpStatus.NO_CONTENT) {
    const error = await response.json();
    throw error;
  }
};
