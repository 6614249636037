import httpStatus from 'http-status';
import { IAuth, IOauthToken, IUser } from '../interfaces';
import { camelize } from '../utils';
import { getAuthHeaders, getHeaders } from './utils';

const authorize = async (
  username: string,
  password: string,
): Promise<IOauthToken> => {
  const response = await fetch('/v2/auth/oauth2/authorize', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: getHeaders(),
  });

  let payload;

  try {
    payload = await response.json();

    if (
      response.status === httpStatus.UNAUTHORIZED &&
      payload?.error?.message !== 'Invalid username or password.'
    ) {
      throw new Error(payload?.error?.message);
    }

    if (response.ok && response.status === httpStatus.OK) {
      return camelize(payload.data);
    }
  } catch (e) {
    console.error(`apis/oauth/authorize error: ${e.message}`); // eslint-disable-line no-console
    throw new Error(
      'There has been an error connecting to the Cloud. Please retry. If this persists please contact the actiMirror/LUNNA team.',
    );
  }

  throw new Error(payload.error.message);
};

const forgotPassword = async (username: string) => {
  const response = await fetch('/v2/auth/credentials/password/forgot', {
    method: 'POST',
    body: JSON.stringify({ username }),
    headers: getHeaders(),
  });

  const payload = await response.json();

  if (response.ok && response.status === 200) {
    return camelize(payload.data);
  }

  throw new Error(payload.error);
};

const confirmForgotPassword = async (
  username: string,
  code: string,
  newPassword: string,
) => {
  const response = await fetch('/v2/auth/credentials/password/confirm-forgot', {
    method: 'POST',
    body: JSON.stringify({ username, code, password: newPassword }),
    headers: getHeaders(),
  });

  const payload = await response.json();

  if (response.ok && response.status === 200) {
    return camelize(payload.data);
  }

  throw new Error(payload.error);
};

const confirmPassword = async (
  username: string,
  code: string,
  newPassword: string,
) => {
  const response = await fetch('/v2/auth/credentials/activate/confirm', {
    method: 'POST',
    body: JSON.stringify({ username, code, newPassword }),
    headers: getHeaders(),
  });

  const payload = await response.json();
  if (response.status === httpStatus.OK) {
    return camelize(payload?.data);
  }

  throw new Error(payload?.error);
};

const token = async (refreshToken: string): Promise<IOauthToken> => {
  const response = await fetch('/v2/auth/oauth2/token', {
    method: 'POST',
    body: JSON.stringify({ refresh_token: refreshToken }),
    headers: getHeaders(),
  });

  const payload = await response.json();

  if (response.ok && response.status === 200) {
    return camelize(payload.data);
  }

  throw new Error(payload.error);
};

const changePassword = async (
  authData: IAuth,
  oldPassword: string,
  newPassword: string,
) => {
  if (!authData?.tokens) {
    throw new Error('No token found in auth context');
  }

  const headers = getAuthHeaders(authData.tokens);
  const response = await fetch('/v2/auth/credentials/password', {
    method: 'PUT',
    body: JSON.stringify({ oldPassword, newPassword }),
    headers,
  });

  const payload = await response.json();

  if (response.ok && response.status === 200) {
    return camelize(payload.data);
  }

  throw new Error(payload.error);
};

const userInfo = async (authData: IAuth): Promise<IUser> => {
  if (!authData?.tokens) {
    throw new Error('No token found in auth context');
  }

  const headers = getAuthHeaders(authData.tokens);
  headers.append('x-actimirror-webclient', 'true');

  const response = await fetch('/v2/auth/oauth2/userInfo', {
    headers,
  });

  const payload = await response.json();

  if (response.ok && response.status === 200) {
    return camelize(payload.data);
  }

  throw new Error(payload.error);
};

export {
  authorize,
  token,
  changePassword,
  forgotPassword,
  confirmForgotPassword,
  confirmPassword,
  userInfo,
};
