import React, { useContext, useState } from 'react';

const ToastContext = React.createContext({
  title: null,
  message: null,
  error: null,
  lastToastTime: Date.now(),
  showed: false,
  toast: ({ error, message, messageTitle, fade }) => {},
  fade: false,
  setShowed: (showed: boolean) => {},
  setLastToastTime: (time: number) => {},
});

const ToastProvider = (props) => {
  const [error, setError] = useState();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [lastToastTime, setLastToastTime] = useState(Date.now());
  const [showed, setShowed] = useState(true);
  const [fade, setFade] = useState(true);

  const toast = ({
    error: err,
    message: msg,
    messageTitle,
    fade: willFade = true,
  }) => {
    setTitle(messageTitle);
    setMessage(msg);
    setError(err);
    setShowed(false);
    setFade(willFade);
  };

  return (
    <ToastContext.Provider
      value={{
        error,
        title,
        message,
        lastToastTime,
        showed,
        fade,
        toast,
        setShowed,
        setLastToastTime,
      }}
      {...props}
    />
  );
};

const useToast = () => useContext(ToastContext);

export { ToastProvider, useToast };
